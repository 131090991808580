import React, { FC } from 'react';
import { DefaultTemplate } from 'templates';
import { Cell, Grid, SectionContainer } from 'components/Grid';
import { Heading } from 'components/Layout';
import { ContactButton } from 'components/Buttons';
import { Link } from 'components/helpers';

import styles from 'scss/landing-page.module.scss';
import gridStyles from 'scss/grid.module.scss';

const LandingPage: FC = () => {
  return (
    <DefaultTemplate
      pageTitle='Data Structures and Algorithms Coding Challenge - Software Engineering Interview Preparation'
      metaDescription='Prepare for Data Structures and Algorithms coding challenges with our program. Two 1.5 hour sessions with personalized exercises, mock interviews, and expert guidance. Gain the confidence to excel in your next interview.'
    >
      <div className={styles.pageMain}>
        <SectionContainer name='data-structure-and-algorithms'>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12 }}>
              <p>
                <Link to='/interview-preparation'>← Back to overview</Link>
              </p>
              <Heading tagLevel={1} classes={gridStyles.marginTop0}>
                <strong>Data Structures and Algorithms Coding Challenge</strong>
              </Heading>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Overview
              </Heading>
              <p>
                We will conduct two 1.5 hour sessions focused specifically on
                the Data Structures and Algorithms coding challenge portion of
                software engineering interviews.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session 1 - 1.5 hours
              </Heading>
              <p>
                We will begin with a diagnostic mock interview to assess your
                current performance and identify areas for improvement. We will
                provide personalized exercises to help you develop your skills.
                Following the first session, you will receive detailed notes
                summarizing our discussion and outlining the exercises to
                complete before the next session.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Session 2 - 1.5 hours
              </Heading>
              <p>
                We will review your completed exercises and conduct another mock
                interview, providing on-the-spot coaching to refine your skills.
              </p>
              <Heading tagLevel={2} classes={gridStyles.marginTop0}>
                Results to expect
              </Heading>
              <p>
                This training will equip you with the knowledge and confidence
                required to excel in Data Structures and Algorithms interviews.
                You will develop the necessary skills to stand out from other
                candidates and perform well in your next interview.
              </p>
              <div className={gridStyles.textCenter}>
                <ContactButton>Email to schedule</ContactButton>
              </div>
            </Cell>
          </Grid>
        </SectionContainer>
      </div>
    </DefaultTemplate>
  );
};

export default LandingPage;
